var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "printSheet",
      attrs: { title: "打印导检单", "mask-closable": false, scrollable: "" },
      model: {
        value: _vm.modalVisible,
        callback: function ($$v) {
          _vm.modalVisible = $$v
        },
        expression: "modalVisible",
      },
    },
    [
      _c(
        "Row",
        [
          _c(
            "Col",
            { attrs: { span: "16" } },
            [
              _c(
                "Alert",
                {
                  staticClass: "ivu-alert-right",
                  staticStyle: { display: "flex" },
                },
                [_c("span", [_vm._v("体检收据")])]
              ),
              _c("div", { staticStyle: { height: "70vh" } }, [
                _c("iframe", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { src: _vm.previewHtmlUrl, frameborder: "0" },
                }),
              ]),
            ],
            1
          ),
          _c("Col", { attrs: { span: "1" } }),
          _vm.spinShow
            ? _c("Spin", { attrs: { size: "large", fix: "" } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }