<template>
  <Modal title="打印导检单" v-model="modalVisible" :mask-closable="false" class="printSheet" scrollable>
    <Row>
      <Col span="16">
        <Alert class="ivu-alert-right" style="display: flex;">
          <span>体检收据</span>
          <!--          <Button @click="printSheet" type="info" size="small" icon="md-print">打印导检单</Button>-->
        </Alert>
        <div style="height: 70vh;">
          <!--          <pdf ref="pdf" id="printPdf" v-for="i in numPages" :key="i" :src="previewHtmlUrl" :page="i" v-show="false"></pdf>-->
          <iframe :src="previewHtmlUrl" frameborder="0" style="width: 100%; height: 100%"></iframe>
          <!--          <pdf ref="pdf" id="printPdf" :src="previewHtmlUrl"></pdf>-->
        </div>
      </Col>
      <Col span="1"></Col>
      <!--            <Col span="7">-->
      <!--                <Alert class="ivu-alert-right" style="display: flex;">-->
      <!--                    <span>样本条码</span>-->
      <!--                    <Button @click="printBarcode" type="info" size="small" icon="md-print">打印样本条码</Button>-->
      <!--                </Alert>-->
      <!--                <div style="margin-top: 20px;max-height: 68vh; overflow-y: auto;">-->
      <!--                    <img :src="item" v-for="(item, index) in barcodeList" :key="index">-->
      <!--                </div>-->
      <!--            </Col>-->
      <Spin size="large" fix v-if="spinShow"></Spin>
    </Row>

    <div slot="footer">
      <Button type="text" @click="closeModal(false)">取消</Button>
    </div>
  </Modal>
</template>
<script>
import {getTemplatePreviewData} from '@/api/healthy/tTemplate'
import pdf from 'vue-pdf'
import {getLodop} from "../../../../public/LodopFuncs";
import {generatorBarcode, testNumGeneratorBarcode} from "../../../api/healthy/tGroupPerson";
import printJS from 'print-js';
import {getGroupByOfficeId} from "../../../api/healthy/tDepartResult";
import {userInfo} from "../../../api/index"
import Cookies from "js-cookie";
import {getTOrderGroup, getTOrderGroupByGroupOrderId, queryTOrderGroupList} from "../../../api/healthy/tOrderGroup";

export default {
  name: "inspectionSheet",
  components: {
    pdf
  },
  props: {
    payType: {
      type: Number,
      default: 0
    },
    value: {
      type: Boolean,
      default: false
    },
    isReviewer: {
      type: Boolean
    },
    passStatus: {
      type: String,
      default: "未登记"
    },
    data: {
      type: Array,
      default: []
    },
    personInfo: {
      type: Object
    },
    projectInfo: {
      type: Array
    },
    orderTotal: {
      type: Number,
      default: 0
    }
  },
  computed: {
    physicalType() {
      return this.$store.state.theme.theme.physicalType;
    }
  },
  data() {
    return {
      modalVisible: this.value,
      numPages: [],
      previewHtmlUrl: "",
      barcodeList: [],
      spinShow: false,
      flag: false,
      testNumCode: '',
      inspectionDoctor: ''
    }
  },
  methods: {
    generatorCode() {
      this.spinShow = true;

      let templateData = {};
      let id = "";
      let goods = [];
      let _this = this;
      // //医院全称
      templateData.hospitalName = _this.$hospitalName.name;

      //团体缴费
      if (this.payType == 1) {

        id = "dc986b58fd1610cc90275d09079e30bd";
        // templateData.name = _this.personInfo.personName;
        // templateData.sex = _this.personInfo.sex;
        // templateData.idCard = _this.personInfo.idCard;
        // templateData.mobile = _this.personInfo.mobile;
        // templateData.workStateText = _this.personInfo.workStateText;
        // templateData.age = _this.personInfo.age;
        // templateData.groupUnit = _this.personInfo.unitName;
        // if (!templateData.groupUnit) {
        //   templateData.groupUnit = _this.personInfo.dept;
        // }
        //templateData.hazardFactorsText = _this.personInfo.hazardFactorsText;
        //templateData.orderTotal = _this.orderTotal;

        for (let i = 0; i < this.data.length; i++) {
          goods.push({
            itemName: this.data[i].name,//科室名
            itemUnit: "元",//检查地址
            itemPrice:this.data[i].prices,
            itemCount:this.data[i].personCount,
            itemFee: this.data[i].prices*this.data[i].personCount,//检查项目名
          });
        }


        goods.sort((a, b) => {
          return a.t - b.t
        })
        templateData.goods = goods;


        getTemplatePreviewData({id: id, templateData: JSON.stringify(templateData)}).then(res => {
          if (res && res.success) {

            this.previewHtml = res.data.replace("template", "div");
            let url = window.location.protocol + "//" + window.location.host + "/tempFileUrl" + this.previewHtml;
            this.previewHtmlUrl = url;
          } else {
            this.$Message.error('预览请求失败');
          }
        }).finally(() => {
          this.spinShow = false;
        });

      } else if (this.payType == 2) {
        //个人缴费
        let orderData = [];
        // getTOrderGroupByGroupOrderId({groupOrderId: this.personInfo.orderId}).then(res => {
        queryTOrderGroupList({groupOrderId: this.personInfo.orderId}).then(res => {
          if (res.success) {
            orderData = res.data;
            this.testNumCode = res.data;
            id = "f5811dd70031131c7a0080ed02f08398";
            templateData.name = _this.personInfo.personName;
            templateData.sex = _this.personInfo.sex;
            templateData.idCard = _this.personInfo.idCard;
            templateData.mobile = _this.personInfo.mobile;
            templateData.workStateText = _this.personInfo.workStateText;
            templateData.age = _this.personInfo.age;
            templateData.groupUnit = _this.personInfo.unitName;
            if (!templateData.groupUnit) {
              templateData.groupUnit = _this.personInfo.dept;
            }
            //templateData.hazardFactorsText = _this.personInfo.hazardFactorsText;
            templateData.orderTotal = _this.orderTotal;
            //if(_this.personInfo.avatar){
            //templateData.headImg = _this.personInfo.avatar.replace("data:image/png;base64,", "");
            //}
            //let base64img = _this.testNumCode;
            //this.inspectionDoctor = JSON.parse(Cookies.get("userInfo"));
            //templateData.nickname = this.inspectionDoctor.nickname;
            //templateData.isMarry = this.personInfo.isMarry;
            //templateData.barCodeImg = base64img;
            for (let i = 0; i < orderData.length; i++) {
              goods.push({
                itemName: orderData[i].name,//科室名
                itemUnit: "元",//检查地址
                itemFee: orderData[i].discountPrice,//检查项目名
              });
            }


            goods.sort((a, b) => {
              return a.t - b.t
            })
            templateData.goods = goods;

            console.log(templateData.feeItems)
            getTemplatePreviewData({id: id, templateData: JSON.stringify(templateData)}).then(res => {
              if (res && res.success) {

                this.previewHtml = res.data.replace("template", "div");
                let url = window.location.protocol + "//" + window.location.host + "/tempFileUrl" + this.previewHtml;
                this.previewHtmlUrl = url;
              } else {
                this.$Message.error('预览请求失败');
              }
            }).finally(() => {
              this.spinShow = false;
            });


          } else {
            this.$Message.error(res.msg)
          }
        })
      }


    },
    closeModal(val) {
      this.$emit('input', val);
      if (this.flag) {
        this.$emit('handleSearch');
      }
    },
    printSheet() {
      let dev = document.getElementById('printPdf');
      dev.style.marginLeft = '0px';
      printJS({
        printable: 'printPdf',
        type: 'html',
        ignoreElements: [],
        targetStyles: ['*']
      });
      dev.style.margin = 'auto';
    },
    officeSort(projectInfo) {
      let t = 10
      if (projectInfo.officeName.trim().toString() == "一般检查") {
        t = 1;
      } else if (projectInfo.officeName.trim().toString() == "内科") {
        t = 2;
      } else if (projectInfo.officeName.trim().toString() == "外科") {
        t = 3;
      } else if (projectInfo.officeName.trim().toString() == "五官科") {
        t = 4;
      } else if (projectInfo.officeName.trim().toString() == "妇科") {
        t = 5;
      } else if (projectInfo.officeName.trim().toString() == "检验科") {
        t = 6;
      } else if (projectInfo.officeName.trim().toString() == "心电图") {
        t = 7;
      } else if (projectInfo.officeName.trim().toString() == "彩超室") {
        t = 8;
      } else if (projectInfo.officeName.trim().toString() == "CT室") {
        t = 9;
      }
      return t;
    }
  }
  ,
  watch: {
    value(val) {
      this.modalVisible = val;
    }
    ,
    modalVisible(val) {
      if (val) {
        this.flag = false;
        this.generatorCode();
      } else {
        this.closeModal(val);
      }
    }
  }
}
</script>

<style lang="less">
.printSheet {
  .ivu-modal {
    width: 80vw !important;

    .ivu-modal-body {
      position: relative;
    }
  }

  .ivu-alert-right {
    padding: 8px;
    font-weight: bold;
    height: 25px;
    line-height: 25px;
    box-sizing: content-box;
    font-size: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0 !important;

    .ivu-alert-message {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
